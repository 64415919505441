<template>
  <b-container class="page-container">
    <bread-crumbs :currentpage="$t('register')" />
    <h1>{{ $t('register') }}</h1>
    <p>{{ $t('fill-in-form-to-register') }}</p>

    <b-row>
      <b-col md="10" sm="12">
        <b-card>
          <b-form @submit.prevent="registerUser">
            <b-row>
              <b-col cols="12">
                <h4>{{ $t('your-details-lowercase') }}</h4>
              </b-col>

              <b-col sm="12" md="6">
                <b-form-group
                  id="input-group-firstname"
                  label-for="input-firstname"
                >
                  <label>
                    {{ $t('firstname') }}
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-input
                    id="input-firstname"
                    v-model="form.firstname"
                    :placeholder="$t('firstname')"
                    type="text"
                    name="firstname"
                    maxlength="50"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>

              <b-col sm="12" md="6">
                <b-form-group
                  id="input-group-lastname"
                  label-for="input-lastname"
                >
                  <label>
                    {{ $t('lastname') }}
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-input
                    id="input-lastname"
                    v-model="form.lastname"
                    :placeholder="$t('lastname')"
                    type="text"
                    name="lastname"
                    maxlength="50"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col sm="12" md="6">
                <b-form-group
                  id="input-group-customer-email"
                  label-for="input-customer-email"
                >
                  <label>
                    {{ $t('emailaddress') }}
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-input
                    id="input-customer-email"
                    v-model="form.email"
                    :placeholder="$t('emailaddress')"
                    type="text"
                    maxlength="255"
                    name="email"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col sm="12" md="6">
                <b-form-group
                  id="input-group-customer-email"
                  label-for="input-customer-email"
                >
                  <label>
                    {{ $t('repeat-email-address') }}
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-input
                    id="input-customer-email"
                    v-model="form.emailConfirmation"
                    :state="emailConfirmState"
                    :placeholder="$t('repeat-email-address')"
                    type="text"
                    maxlength="255"
                    name="email-confirmation"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col sm="12" md="6">
                <b-form-group id="input-group-street" label-for="input-street">
                  <label>
                    {{ $t('street') }}
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-input
                    id="input-street"
                    v-model="form.street"
                    :placeholder="$t('street')"
                    type="text"
                    maxlength="50"
                    name="street"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col sm="6" md="6">
                <b-form-group
                  id="input-group-housenumber"
                  label-for="input-housenumber"
                >
                  <label>
                    {{ $t('housenumber') }}
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-input
                    id="input-housenumber"
                    v-model="form.houseNumber"
                    :placeholder="$t('housenumber')"
                    type="text"
                    maxlength="10"
                    name="houseNumber"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>

              <b-col sm="12" md="6">
                <b-form-group id="input-group-postal" label-for="input-postal">
                  <label>
                    {{ $t('zipcode') }}
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-input
                    id="input-postal"
                    v-model="form.postcode"
                    :placeholder="$t('zipcode')"
                    type="text"
                    maxlength="10"
                    name="postcode"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col sm="12" md="6">
                <b-form-group id="input-group-city" label-for="input-city">
                  <label>
                    {{ $t('city') }}
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-input
                    id="input-city"
                    v-model="form.city"
                    :placeholder="$t('city')"
                    type="text"
                    name="city"
                    maxlength="50"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>

              <b-col sm="12" md="6">
                <b-form-group id="input-group-tel" label-for="input-tel">
                  <label>
                    {{ $t('gsm') }}
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-input
                    id="input-tel"
                    v-model="form.CONTACT_MobilePhone"
                    :placeholder="$t('gsm')"
                    type="text"
                    name="mobiletel"
                    maxlength="50"
                    required
                    oninput="javascript: this.value = this.value.replace(/[A-Za-z]/gi, '');"
                  ></b-form-input>
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <hr />
                <password-helper-block />
              </b-col>
              <b-col sm="12" md="6">
                <span
                  v-if="!passwordValidation && form.password.length > 0"
                  class="text-danger"
                  >{{ $t('your-password-does-not-meet-conditions') }}</span
                >
                <br v-if="passwordValidation || form.password.length === 0" />
                <b-form-group
                  id="input-group-password"
                  label-for="input-password"
                >
                  <label>
                    {{ $t('password') }}
                    <span class="text-danger">*</span>
                  </label>
                  <b-input-group>
                    <b-form-input
                      id="input-password"
                      v-model="form.password"
                      :type="showPassword ? 'text' : 'password'"
                      :state="passwordValidation"
                      :placeholder="$t('password')"
                      name="password"
                      required
                      min="8"
                      max="32"
                    ></b-form-input>
                    <b-input-group-append>
                      <b-button
                        @click="showPasswordToggle()"
                        title="Wachtwoord tonen"
                        variant="primary"
                      >
                        <icon-eye v-if="!showPassword" />
                        <icon-eye-slash v-if="showPassword" />
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col sm="12" md="6">
                <span
                  v-if="
                    !passwordConfirmState && form.confirmPassword.length > 0
                  "
                  class="text-danger"
                  >{{ $t('passwords-not-the-same') }}</span
                >
                <br
                  v-if="
                    passwordConfirmState || form.confirmPassword.length === 0
                  "
                />
                <b-form-group
                  id="input-group-passwordConfirm"
                  label-for="input-passwordConfirm"
                >
                  <label>
                    {{ $t('repeat-password') }}
                    <span class="text-danger">*</span>
                  </label>
                  <b-input-group>
                    <b-form-input
                      id="input-passwordConfirm"
                      v-model="form.confirmPassword"
                      :type="showPassword ? 'text' : 'password'"
                      :state="passwordConfirmState && passwordValidation"
                      :placeholder="$t('repeat-your-password')"
                      name="confirmPassword"
                      required
                      min="8"
                      max="32"
                    ></b-form-input>
                    <b-input-group-append>
                      <b-button
                        @click="showPasswordToggle()"
                        :title="$t('show-password')"
                        variant="primary"
                      >
                        <icon-eye v-if="!showPassword" />

                        <icon-eye-slash v-if="showPassword" />
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>

              <!-- Invoice details -->
              <b-col cols="12">
                <hr />
                <h4>{{ $t('invoicing-details') }}</h4>
              </b-col>
              <b-col cols="12" class="mb-2">
                <b-form-checkbox
                  id="invoiceSameAsOrganisationAddress"
                  v-model="invoiceSameAsOrganisationAddress"
                  name="invoiceSameAsOrganisationAddress"
                >
                  {{ $t('invoice-same-as-regular') }}
                </b-form-checkbox>
              </b-col>
              <b-col v-if="!invoiceSameAsOrganisationAddress" cols="12">
                <b-row>
                  <b-col sm="12" md="6">
                    <b-form-group
                      id="input-group-customer-name"
                      label-for="input-customer-name"
                    >
                      <label>
                        {{ $t('companyname') }}
                        <span class="text-danger">*</span>
                      </label>
                      <b-form-input
                        id="input-customer-invoice-companyname"
                        v-model="form.InvoiceCompanyName"
                        :required="!invoiceSameAsOrganisationAddress"
                        :placeholder="$t('companyname')"
                        type="text"
                        maxlength="50"
                        max="50"
                        name="InvoiceCompanyName"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" md="6">
                    <b-form-group
                      id="input-group-customer-invoice-vatnumber"
                      label-for="input-customer-invoice-vatnumber"
                    >
                      <label>{{ $t('company-number') }}</label>
                      <b-form-input
                        id="input-customer-invoice-vatnumber"
                        v-model="form.VATNo"
                        :placeholder="$t('company-number')"
                        type="text"
                        maxlength="30"
                        name="invoiceVatnumber"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" md="12">
                    <b-form-group
                      id="input-group-customer-invoice-firstname"
                      label-for="input-customer-invoice-firstname"
                    >
                      <label>
                        {{ $t('firstname') }}
                        <span class="text-danger">*</span>
                      </label>
                      <b-form-input
                        id="input-customer-invoice-firstname"
                        v-model="form.InvoiceFirstName"
                        :required="!invoiceSameAsOrganisationAddress"
                        :placeholder="$t('firstname')"
                        type="text"
                        maxlength="50"
                        name="InvoiceFirstName"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" md="12">
                    <b-form-group
                      id="input-group-customer-invoice-lastname"
                      label-for="input-customer-invoice-lastname"
                    >
                      <label>
                        {{ $t('lastname') }}
                        <span class="text-danger">*</span>
                      </label>
                      <b-form-input
                        id="input-customer-invoice-lastname"
                        v-model="form.InvoiceLastName"
                        :required="!invoiceSameAsOrganisationAddress"
                        :placeholder="$t('lastname')"
                        type="text"
                        maxlength="50"
                        name="InvoiceLastName"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col sm="12" md="6">
                    <b-form-group
                      id="input-group-invoice-street"
                      label-for="input-invoice-street"
                    >
                      <label>
                        {{ $t('street') }}
                        <span class="text-danger">*</span>
                      </label>
                      <b-form-input
                        id="input-street"
                        v-model="form.InvoiceStreet"
                        :required="!invoiceSameAsOrganisationAddress"
                        :placeholder="$t('street')"
                        type="text"
                        maxlength="50"
                        name="InvoiceStreet"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col sm="6" md="6">
                    <b-form-group
                      id="input-group-invoice-housenumber"
                      label-for="input-invoice-housenumber"
                    >
                      <label>
                        {{ $t('housenumber') }}
                        <span class="text-danger">*</span>
                      </label>
                      <b-form-input
                        id="input-housenumber"
                        v-model="form.InvoiceHouseNumber"
                        :required="!invoiceSameAsOrganisationAddress"
                        :placeholder="$t('housenumber')"
                        type="text"
                        maxlength="10"
                        name="InvoiceHouseNumber"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" md="6">
                    <b-form-group
                      id="input-group-invoice-postal"
                      label-for="input-invoice-postal"
                    >
                      <label>
                        {{ $t('zipcode') }}
                        <span class="text-danger">*</span>
                      </label>
                      <b-form-input
                        id="input-postal"
                        v-model="form.InvoiceZipCode"
                        :required="!invoiceSameAsOrganisationAddress"
                        :placeholder="$t('zipcode')"
                        type="text"
                        maxlength="10"
                        name="InvoiceZipCode"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" md="6">
                    <b-form-group
                      id="input-group-invoice-city"
                      label-for="input-city"
                    >
                      <label>
                        {{ $t('city') }}
                        <span class="text-danger">*</span>
                      </label>
                      <b-form-input
                        id="input-city"
                        v-model="form.InvoiceCity"
                        :required="!invoiceSameAsOrganisationAddress"
                        :placeholder="$t('city')"
                        type="text"
                        maxlength="50"
                        name="InvoiceCity"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" md="6">
                    <b-form-group
                      id="input-group-invoice-phone"
                      label-for="input-phone"
                    >
                      <label>
                        {{ $t('telephonenumber') }}
                        <span class="text-danger">*</span>
                      </label>
                      <b-form-input
                        id="input-city"
                        v-model="form.InvoiceTel"
                        :required="!invoiceSameAsOrganisationAddress"
                        :placeholder="$t('telephonenumber')"
                        type="text"
                        maxlength="20"
                        oninput="javascript: this.value = this.value.replace(/[A-Za-z]/gi, '');"
                        name="InvoiceTel"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" md="6">
                    <b-form-group
                      id="input-group-invoice-email"
                      label-for="input-email"
                    >
                      <label>
                        {{ $t('emailaddress') }}
                        <span class="text-danger">*</span>
                      </label>
                      <b-form-input
                        id="input-invoice-email"
                        v-model="form.InvoiceEmail"
                        :required="!invoiceSameAsOrganisationAddress"
                        :placeholder="$t('emailaddress')"
                        type="text"
                        maxlength="250"
                        name="InvoiceEmail"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              <!-- END invoice details -->

              <b-col>
                <hr />
              </b-col>
              <b-col cols="12" class="mb-1">
                <b-form-checkbox
                  id="newsletter"
                  v-model="form.newsletter"
                  :value="true"
                  :unchecked-value="false"
                  name="newsletter"
                >
                  {{ $t('receive-newsletter') }}
                </b-form-checkbox>
              </b-col>
              <b-col cols="12">
                <b-form-checkbox
                  id="agree-with-terms"
                  v-model="form.agreedWithTerms"
                  :required="true"
                  name="agree-with-terms"
                  value="accepted"
                  unchecked-value="not_accepted"
                >
                  {{ $t('i-agree-with') }}
                  <a href="https://rentmagic.net" target="_blank">
                    {{ $t('the-rules') }}</a
                  >
                  {{ $t('of-the-companyname') }}
                  <span class="text-danger">*</span>
                </b-form-checkbox>
              </b-col>

              <b-col cols="12" class="text-right">
                <b-alert
                  v-if="errorMessage"
                  show
                  variant="danger"
                  class="mt-3 text-left"
                  >{{ errorMessage }}</b-alert
                >
                <b-button
                  :disabled="registering"
                  class="mb-2"
                  type="submit"
                  variant="primary"
                >
                  {{ $t('register') }}
                  <icon-spinner v-if="registering" />
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import PasswordHelperBlock from '@/components/global/PasswordHelperBlock.vue'
import IconEye from '@/components/icons/IconEye'
import IconEyeSlash from '@/components/icons/IconEyeSlash'
import IconSpinner from '@/components/icons/IconSpinner'
const { register } = require('@/services/AuthorizationService')

export default {
  components: {
    IconSpinner,
    IconEye,
    IconEyeSlash,
    PasswordHelperBlock
  },
  data() {
    return {
      form: {
        username: '',
        email: '',
        password: '',
        confirmPassword: '',
        street: '',
        houseNumber: '',
        houseNumberAddition: '',
        postcode: '',
        city: '',
        tel: '',
        customerOrganisationNumber: '',
        vatid: '',
        website: '',
        targetAudience: '',
        firstname: '',
        lastname: '',
        contactEmail: '',
        contactStreet: '',
        contactHouseNumber: '',
        contactPostcode: '',
        contactCity: '',
        CONTACT_Tel: '',
        CONTACT_MobilePhone: '',
        CUST_Notes: '',
        newsletter: false,
        agreedWithTerms: false,
        CUST_SocialMedia: '',
        InvoiceCompanyName: '',
        InvoiceFirstName: '',
        InvoiceLastName: '',
        InvoiceStreet: '',
        InvoiceHouseNumber: '',
        InvoiceHouseNumberAddition: '',
        InvoiceZipCode: '',
        InvoiceCity: '',
        InvoiceTel: '',
        InvoiceEmail: '',
        VATNo: ''
      },
      incorrectPassword: false,
      registering: false,
      error: false,
      errorMessage: null,
      showPassword: false,
      invoiceSameAsOrganisationAddress: true
    }
  },
  metaInfo: {
    title: `Registreren`
  },
  computed: {
    passwordConfirmState() {
      if (this.form.password != '') {
        if (this.form.password == this.form.confirmPassword) {
          return true
        }
        return false
      }
      return null
    },
    emailConfirmState() {
      if (this.form.email != '') {
        if (this.form.email == this.form.emailConfirmation) {
          return true
        }
        return false
      }
      return null
    },
    passwordValidation() {
      let password = this.form.password
      if (password != '') {
        if (password.length < 8) {
          return false
        }

        if (!password.match(/[!@$%&\-+*()&^]/)) return false
        if (!password.match(/[0-9]/)) return false
        if (!password.match(/[a-z]/)) return false
        if (!password.match(/[A-Z]/)) return false
        if (!/^([0-9a-zA-Z!@$%&\-+*()&^]*)$/.exec(password)) return false

        return true
      }

      return null
    }
  },
  methods: {
    showPasswordToggle: function() {
      if (this.showPassword) {
        this.showPassword = false
      } else {
        this.showPassword = true
      }
    },
    async registerUser(event) {
      this.registering = true
      event.preventDefault()

      if (this.form.password != this.form.confirmPassword) {
        this.registering = false
        return
      }
      if (!this.passwordValidation) {
        this.registering = false
        this.incorrectPassword = true
        return
      }

      if (!this.passwordValidation && !this.loggedIn) {
        this.displayErrorMessage(
          this.$t('filled-in-password-not-meeting-conditions')
        )
        return
      }

      let InvoiceCompanyName = this.form.InvoiceCompanyName
      let InvoiceStreet = this.form.InvoiceStreet
      let InvoiceHouseNumber = this.form.InvoiceHouseNumber
      let InvoiceHouseNumberAddition = this.form.InvoiceHouseNumberAddition
      let InvoiceZipCode = this.form.InvoiceZipCode
      let InvoiceCity = this.form.InvoiceCity
      let InvoiceTel = this.form.InvoiceTel
      let InvoiceEmail = this.form.InvoiceEmail
      let InvoiceFirstName = this.form.InvoiceFirstName
      let InvoiceLastName = this.form.InvoiceLastName

      if (this.invoiceSameAsOrganisationAddress) {
        InvoiceCompanyName = this.form.companyName
        InvoiceStreet = this.form.street
        InvoiceHouseNumber = this.form.houseNumber
        InvoiceHouseNumberAddition = this.form.houseNumberAddition
        InvoiceZipCode = this.form.postcode
        InvoiceCity = this.form.city
        InvoiceTel = this.form.tel || this.form.mobiletel
        InvoiceEmail = this.form.email
        InvoiceFirstName = this.form.firstname
        InvoiceLastName = this.form.lastname
      }
      const result = await register({
        username: this.form.email,
        email: this.form.email,
        password: this.form.password,
        newsletter: this.form.newsletter,
        street: this.form.street,
        customerOrganisationNumber: this.form.customerOrganisationNumber,
        houseNumber: this.form.houseNumber,
        houseNumberAddition: this.form.houseNumberAddition,
        postcode: this.form.postcode,
        city: this.form.city,
        tel: this.form.CONTACT_MobilePhone,
        companyName: `${this.form.firstname} ${this.form.lastname}`,
        website: this.form.website,
        firstname: this.form.firstname,
        lastname: this.form.lastname,
        CONTACT_email: this.form.email,
        CONTACT_tel: this.form.CONTACT_MobilePhone,
        CONTACT_MobilePhone: this.form.CONTACT_MobilePhone,
        InvoiceCompanyName,
        InvoiceStreet,
        InvoiceHouseNumber,
        InvoiceHouseNumberAddition,
        InvoiceZipCode,
        InvoiceCity,
        InvoiceTel,
        InvoiceEmail,
        InvoiceFirstName,
        InvoiceLastName,
        VATNo: this.form.VATNo,
        Locale: this.$i18n.locale
      })

      if (
        result.data.Message === 'The entered e-mail address is unauthorized.' ||
        result.data.Message ===
          'This e-mailaddress is already in use by another user.'
      ) {
        this.displayErrorMessage(this.$t('email-in-use-register'))

        return
      }

      if (result.data.Message != 'InvalidModelState' && result.data === 'OK') {
        if (this.loggedIn) {
          this.$router.push({ name: 'accountCreatedExistingAccount' })
        } else {
          this.$router.push({ name: 'accountEmailSend' })
        }
      } else {
        this.displayErrorMessage(
          this.$t('something-went-wrong-making-your-account')
        )
        return
      }

      this.registering = false
    },
    displayErrorMessage: function(errorMessage) {
      this.registering = false
      this.error = true
      this.errorMessage = errorMessage
    }
  }
}
</script>
